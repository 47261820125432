<template>
    <div class="car-list">
        <div class="search-toolbar">
            <div class="tool-item">
                <span class="item-label">车牌号码：</span>
                <el-input class="item-input" placeholder="请输车牌号码"></el-input>
            </div>
            <div class="tool-item tool-buttons">
                <el-button type="primary">搜索</el-button>
                <el-button>重置</el-button>
            </div>
        </div>
        <div class="table-container">
            <div class="buttons-wrapper">
                <el-button type="primary" @click="openAddDialog()">新建</el-button>
                <el-button type="danger" @click="openDelDialog()">删除</el-button>
            </div>
            <div class="table-wrapper">
                <el-table
                    :cell-style="{textAlign:'center'}"
                    :data="carList"
                    :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
                    @selection-change="getCarSelection"
                >
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        label="车牌号"
                        prop="number"
                    >
                    </el-table-column>
                    <el-table-column
                        label="车辆类型"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.type==='SMALL'">小型救援车</span>
                            <span v-if="scope.row.type==='HEAVY'">重型拖车</span>
                            <span v-if="scope.row.type==='FLATBED'">平板拖车</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="技师姓名"
                        prop="userName"
                    >
                    </el-table-column>
                    <el-table-column
                        label="年检时间"
                        prop="reviewedAt"
                    >
                    </el-table-column>
                    <el-table-column
                        label="车辆状态"
                    >
                        <template #default="scope">
                            <span>{{ scope.row.status ? "正常" : "维修" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="创建时间"
                        prop="createdAt"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template #default="scope">
                            <el-button size="mini" @click="openDetailDialog(scope.row.id)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination-wrapper">
                <el-pagination
                    :current-page="page.page"
                    :page-size="page.size"
                    :total="carTotal"
                    background
                    layout="sizes,prev, pager, next,jumper"
                    @current-change="changePageCurrent"
                    @size-change="changePageSize"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    <!--新增弹窗-->
    <CarAdd v-model:show="addDialog.show" @updateList="getCarList"></CarAdd>
    <!--查看/编辑弹窗-->
    <CarDetail :id="detailDialog.id" v-model:show="detailDialog.show" @updateList="getCarList"></CarDetail>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {DEL_CARS, GET_CAR_LIST, GET_USER_DETAIL} from "../../api/dataProvider";
import {ElMessage, ElMessageBox} from "element-plus";
import CarAdd from "./CarAdd.vue"
import CarDetail from "./CarDetail.vue"

export default {
    name: "carList",
    components: {
        CarAdd,
        CarDetail
    },
    setup() {
        let state = reactive({
            // 车辆列表
            carList: [],
            // 车辆选中IDS
            carSelection: [],
            // 分页信息
            carTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
            // 新增弹窗
            addDialog: {
                show: false
            },
            // 查看/编辑弹窗
            detailDialog: {
                show: false,
                id: ""
            },
        })

        onMounted(() => {
            getCarList()
        })

        // 获取车辆列表
        let getCarList = () => {
            GET_CAR_LIST(
                {
                    ...state.page,
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.carList = res.data.data
                        state.carTotal = res.data.page.total
                        state.carList.map((item) => {
                            GET_USER_DETAIL(
                                item.userId,
                                (cres) => {
                                    if (cres.data.status === 200) {
                                        item.userName = cres.data.data.realname
                                    }
                                }
                            )
                        })
                        console.log(res.data)
                    } else {
                        ElMessage({
                            message: "列表获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                }
            )
        }

        // 获取列表选中id列表
        let getCarSelection = (arr) => {
            let idArr = []
            arr.map((item) => {
                idArr.push(item.id)
            })
            state.carSelection = idArr
            console.log(state.carSelection)
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getCarList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getCarList()
        }

        // 打开新增弹窗
        let openAddDialog = () => {
            state.addDialog.show = true
        }

        // 前往详情
        let openDetailDialog = (id) => {
            state.detailDialog.id = id
            state.detailDialog.show = true
        }

        // 打开删除弹窗
        let openDelDialog = () => {
            ElMessageBox.confirm("此操作将永久删除选中车辆，是否继续？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                    DEL_CARS(
                        {
                            id: state.carSelection
                        },
                        (res) => {
                            if (res.data.status === 200) {
                                ElMessage({
                                    type: "success",
                                    message: "删除成功!"
                                });
                                getCarList()
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "删除失败！"
                                });
                            }
                        }
                    )
                }
            )
        }

        return {
            ...toRefs(state),
            getCarList,
            getCarSelection,
            changePageCurrent,
            changePageSize,
            openAddDialog,
            openDetailDialog,
            openDelDialog
        }
    },
}
</script>

<style lang="scss" scoped>
.car-list {

}
</style>
<template>
    <el-dialog
        v-model="show"
        title="编辑车辆"
        width="700px"
    >
        <div v-loading="loading" class="car-detail">
            <div class="basic-info">
                <el-form label-position="left" label-width="100px">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="车牌号：">
                                <el-input v-model="postForm.number" placeholder="请输入车牌号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="车型：">
                                <el-select
                                    v-model="postForm.type"
                                    placeholder="请选择车辆类型"
                                >
                                    <el-option
                                        v-for="(item,index) in typeList"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="车辆状态：">
                                <el-select
                                    v-model="postForm.status"
                                    placeholder="请选择车辆状态"
                                >
                                    <el-option
                                        v-for="(item,index) in statusList"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="年审时间：">
                                <el-date-picker
                                    v-model="postForm.reviewedAt"
                                    placeholder="请选择年审时间"
                                    type="date"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属技师：">
                                <el-select
                                    v-model="postForm.userId"
                                    placeholder="请选择所属技师"
                                    clearable
                                >
                                    <el-option
                                        v-for="(item,index) in technicianList"
                                        :key="index"
                                        :label="item.realname"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <template v-if="!loading" #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="edit()">编辑</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {GET_CAR_DETAIL, GET_USER_LIST, UPDATE_CAR_LIST} from "../../api/dataProvider";
import {ElMessage} from "element-plus";

export default {
    name: "CarDetail",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            loading: false,
            // 车辆类型
            typeList: [
                {id: "SMALL", title: "小型救援车"},
                {id: "HEAVY", title: "重型拖车"},
                {id: "FLATBED", title: "平板拖车"},
            ],
            // 车辆类型
            statusList: [
                {id: true, title: "正常"},
                {id: false, title: "维修"},
            ],
            // 技师列表
            technicianList: [],
            postForm: {}
        })

        onMounted(() => {
            if (props.id) {
                getDetail()
                getTechnicianList()
            }
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        watch(() => props.id, (val) => {
            if (val) {
                getDetail()
                getTechnicianList()
            }
        })

        // 获取技师列表
        let getTechnicianList = () => {
            GET_USER_LIST(
                {
                    size: -1,
                    roleId: 4
                },
                (res) => {
                    state.technicianList = res.data.data
                }
            )
        }

        let getDetail = () => {
            state.loading = true
            GET_CAR_DETAIL(
                props.id,
                (res) => {
                    if (res.data.status === 200) {
                        state.postForm = res.data.data
                    } else {
                        ElMessage({
                            message: "数据获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    state.loading = false
                }
            )
        }

        // 编辑
        let edit = () => {
            UPDATE_CAR_LIST(
                {
                    ...state.postForm
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            type: "success",
                            message: "修改成功!"
                        });
                        updateList()
                    } else {
                        ElMessage({
                            type: "error",
                            message: "修改失败！"
                        });
                    }
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }

        return {
            ...toRefs(state),
            getTechnicianList,
            getDetail,
            edit,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.car-detail {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>